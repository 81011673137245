import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllRides } from "../../../../../redux/actions/rides_actions";
import AllRidesTableComponent from "./all_rides_table_component";
import { Dropdown } from "primereact/dropdown";
import { axiosApi } from "../../../constants/axios_api";
import { useFormik } from "formik";
import appUrl from "../../../constants/appUrl";
import { Button } from "primereact/button";


export default function AllRidesComponents({ flag, userType, permissions }) {
    const getAllRidesReducer = useSelector((state) => state.getAllRides);
    const { rides, loading, count } = getAllRidesReducer;
    const [pageNumber, setPageNumber] = useState(0);
    const [rows, setRows] = useState(50);
    const [corporateList , setCorporateList]= useState()

     const formik = useFormik({
        initialValues: {
            corporate:''
        }
     })
    
    
    const dispatch = useDispatch();

    let corporate = formik.values.corporate

    useEffect(() => {
        
        dispatch(getAllRides(flag, userType, pageNumber, rows, corporate));
    }, [flag, userType, pageNumber, rows, corporate]);

      const getallCorporates = async () => {
        const response = await axiosApi.get(`${appUrl?.baseUrl}api/corporate/corporateLov`)
        setCorporateList(response?.data?.data)
    }

    useEffect(() => {
        getallCorporates()
    }, [])

   

    return (
        <>
            <div className="col-12 flex" style={{ justifyContent: 'flex-end' }}>
            <Dropdown className="mr-3 text-left" options={corporateList} optionLabel="name" optionValue="_id" id="corporate" name="corporate" value={formik.values.corporate || ''} onChange={formik.handleChange} placeholder="Select Corporate" />
                <Button label="Clear Corporate" onClick={()=>formik.resetForm()}/>
            </div>
            <AllRidesTableComponent rows={rows} setRows={setRows} permissions={permissions} rides={rides} userType={userType} loading={loading} count={count} setPageNumber={setPageNumber} flag={flag} />
        </>
    );
}
