/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import VModelDialoge from "../../vehiclemanagement/components/vehicle-model-dailoge";
import { confirmDialog } from "primereact/confirmdialog";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import { useDispatch, useSelector } from "react-redux";
import { getVehicleMakers } from "../../../../../redux/slices/vehicle_makers_slice";
import { getVehicleType } from "../../../../../redux/slices/vehicle_type_slice";
// import CustomInputField from "../../../components/custom_input_filed";
import { useFormik } from "formik";
import * as Yup from "yup";
import { deleteVehicleModel, resetAddEditVehicleModels, vehicleModelList } from "../../../../../redux/slices/vehicle_models_slice";

export const vehicleModel = ({ permissions }) => {
    //redux
    const { data: vehicleTypeList } = useSelector((state) => state.vehicleType);
    const { data: vehicleMakersList } = useSelector((state) => state.vehicleMakers);
    const { data: vehicleModelData, deleteModelSuccess, deleteError } = useSelector((state) => state.vehicleModel);

    //hooks
    const [vehicleType, setVehicleType] = useState();
    const [vehicleMakers, setVehicleMakers] = useState();
    const [status, setStatus] = useState(0);

    const [editUser, setEditUser] = useState(null);
    const [AddType, setAddType] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const dispatch = useDispatch();

    const clearFilters = () => {
        // Clear formik values
        setGlobalFilterValue("");
        setVehicleType("");
        setVehicleMakers("");
        setStatus("");
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        });

        // Dispatch with default parameters to fetch all records
        dispatch(
            vehicleModelList({
                pageNumber: 0,
                vehicleType: "",
                vehicleMake: "",
                status: "",
                text: "", // Assuming 'text' is used for global search; set to empty to clear.
            })
        );
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const statusList = [
        {
            status: "Active",
            value: 0,
        },
        {
            status: "Deleted",
            value: 1,
        },
    ];
    // dialog
    const dialogFuncMap = {
        AddType: setAddType,
    };
    const onClick = (name) => {
        dialogFuncMap[`${name}`](true);
    };
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };
    useEffect(() => {
        if (vehicleTypeList === undefined) {
            dispatch(getVehicleType(vehicleType));
        }
        // //console.log(vehicleType)
    }, [vehicleType, dispatch]);
    useEffect(() => {
        if (vehicleMakersList === undefined) {
            dispatch(getVehicleMakers(vehicleMakers));
        }
    }, [vehicleMakers, dispatch]);

    useEffect(() => {
        if (vehicleType !== "" && vehicleMakers !== "") {
            let body = {
                vehicleType: vehicleType,
                vehicleMake: vehicleMakers,
                status,
            };
            dispatch(vehicleModelList(body));
        }
    }, [vehicleType, vehicleMakers, status]);

    let deleteId;
    const accept = () => {
        let data = {
            modelId: deleteId,
        };
        dispatch(deleteVehicleModel(data));
    };
    const confirmDeleteAd = () => {
        confirmDialog({
            message: "Do you want to delete this vehicle model?",
            header: "Delete Confirmation",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            accept,
        });
    };
    useEffect(() => {
        if (deleteModelSuccess !== undefined) {
            if (deleteModelSuccess === true) {
                toast.success("Vehicle Models deleted");
                // dispatch(vehicleModelList());
            } else {
                toast.error(deleteError);
            }
        }
        return () => {
            dispatch(resetAddEditVehicleModels());
        };
    }, [deleteModelSuccess, deleteError]);

    const modelTemp = (rowData) => {
        // const capital = rowData.model.toUpperCase();
        const capital = rowData.model.charAt(0).toUpperCase() + rowData.model.slice(1).toLowerCase();
        return <div>{capital}</div>;
    };

    const handleEdit = (rowData) => {
        setEditUser(rowData);
        onClick("AddType");
    };

    const actionTemplate = (rowData) => {
        return (
            <div className="">
                {permissions?.edit && <Button tooltip="" icon="pi pi-pencil" tooltipOptions={{ position: "top" }} onClick={() => handleEdit(rowData)} className="edit mr-2 p-button-success p-button-rounded" />}{" "}
                {permissions?.delete && (
                    <Button
                        tooltip="Delete"
                        icon="pi pi-trash"
                        tooltipOptions={{ position: "top" }}
                        onClick={() => {
                            deleteId = rowData._id;
                            confirmDeleteAd();
                        }}
                        className="edit mr-2 p-button-danger p-button-rounded"
                    />
                )}
            </div>
        );
    };

    const validationSchema = Yup.object().shape({
        type: "",
        make: "",
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            type: "",
            make: "",
        },
        // onSubmit: async (data) => {
        // },
    });
    const statusTemplate = (rowData) => {
        console.log(rowData);  // Log the entire rowData to inspect its structure
        return <span>{rowData.status === 0 ? "Active" : "Inactive"}</span>;
    };
    
        return (
        <div className="card">
            <Dialog header={editUser == null ? "Add Vehicle Models" : "Update Vehicle Models"} visible={AddType} onHide={() => onHide("AddType")} breakpoints={{ "960px": "75vw", "640px": "100vw" }} style={{ width: "70vw" }} className="Modal_class">
                <VModelDialoge vehicleModelList={vehicleModelList} permissions={permissions} editUser={editUser} modelId={editUser?._id} onHide={() => onHide("AddType")} />
            </Dialog>

            <div className="grid pb-3">
                <div className="col-12 md:col-2 mt-3" style={{display:"flex",alignItems:"top"}}>
                    <h4>Vehicle model</h4>
                </div>
                <div className="col-12 md:col-10" >
                    <div className="mb-9 text-right">
                        <Button  label="Clear Filters" icon="pi pi-times" className=" mr-3 text-left" onClick={clearFilters} />
                        <Dropdown style={{ width: "215px" }} className="mr-3 mt-1 text-left" options={vehicleTypeList} optionLabel="type" optionValue="_id" value={vehicleType} onChange={(e) => setVehicleType(e.value)} placeholder="Select a Vehicle Type " />
                        <Dropdown style={{ width: "215px" }} className="mr-3 mt-1 text-left" options={vehicleMakersList} optionLabel="make" optionValue="_id" value={vehicleMakers} onChange={(e) => setVehicleMakers(e.value)} placeholder="Select Vehicle Makers" />
                        <Dropdown style={{ width: "215px" }} className="mr-3 mt-1 text-left" options={statusList} optionLabel="status" optionValue="value" value={status} onChange={(e) => setStatus(e.value)} placeholder="Select status" />

                        {/* <CustomInputField iden='type' placeholder="Select a Vehicle Type " formik={formik} type='dropdown' options={vehicleTypeList} optionLabel='type' optionValue='_id' value={vehicleType} onChange={(e) => setVehicleType(e.value)}         />
                        <CustomInputField iden='make' placeholder="Select Vehicle Makers"  formik={formik} type='dropdown' options={vehicleMakersList} optionLabel='make' optionValue='_id' value={vehicleMakers}  onChange={(e) => setVehicleMakers(e.value)} /> */}

                        <span className="p-input-icon-right mr-1">
                            <InputText className="mbl_view mt-1" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                        </span>
                        <Button
                            label="Add new"
                            style={{ width: "140px" }}
                            className="p-button-success p-button-rounded p-button-sm mt-1"
                            onClick={() => {
                                setEditUser(null);
                                onClick("AddType");
                            }}
                        />
                    </div>
                </div>
            </div>

            <DataTable className="p-datatable-gridlines" filters={filters} rows={50} paginator responsiveLayout="scroll" key="id" emptyMessage="No record found." value={vehicleModelData} globalFilterFields={["model"]}>
                <Column body={modelTemp} header="Vehicle Model" />
                <Column body={(rowData) => rowData?.make?.make} header="Vehicle Make" />
                <Column body={(rowData) => rowData?.type?.type} header="Vehicle Type" />
                <Column field="seatingCapacity" header="Seating Capacity" />
                {/* <Column body={statusTemplate} header="Status" /> */}

                <Column header="Action" body={actionTemplate} className="text-center Action_col" />
            </DataTable>
        </div>
    );
};
