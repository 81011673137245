import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditUserDialog from "../components/edit_passenger_dialog";
import { useHistory } from "react-router-dom";
import { Image } from "primereact/image";
import appUrl from "../../../constants/appUrl";
import { Avatar } from "primereact/avatar";
import UserProfileDialog from "../components/user_profile_dialog";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import CustomPageHeader from "../../../components/custom_page_header";
import { getAllUsersList } from "../../../../../redux/slices/users_slice";
import { Paginator } from "primereact/paginator";
import moment from "moment";

export default function AllUsersScreen() {
    //redux
    const getUsersListReducer = useSelector((state) => state.usersList);
    const { data, loading, count } = getUsersListReducer;
    const [showProfileDialog, setProfileDialog] = useState(false);
    const [first, setFirst] = useState(0);
    const [defaultImage, setDefaultImage] = useState("app/black-watch.jpg");

    //hooks
    const [editUser, setEditUser] = useState(null);
    const [editDialog, setEditDialog] = useState(false);
    const history = useHistory();
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const dispatch = useDispatch();
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    //dialogs
    const dialogFuncMap = {
        editDialog: setEditDialog,
        showProfileDialog: setProfileDialog,
    };
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };
    //use effects
    useEffect(() => {
        dispatch(getAllUsersList());
    }, []);
    const onPageChange = (event) => {
        setFirst(event.first);
        dispatch(getAllUsersList(event.first / event.rows));
    };
    //methods
    //table body
    const actionTemplate = (rowData) => {
        return (
            <div className="Edit_Icon">
                <Button
                    tooltip={rowData?.totalRatingCount ? (rowData?.totalRatingCount / rowData?.totalRatingCount).toFixed(1).toString() : "0.0"}
                    onClick={() => {
                        //this route to be changed
                        history.push(`/api/user/ratings/list/${rowData?._id}/2`);
                    }}
                    icon="pi pi-star-fill"
                    tooltipOptions={{ position: "top" }}
                    className="p-button-warning m-2 p-button-rounded"
                />

                <Button
                    tooltip="View Status"
                    icon="pi pi-pencil"
                    tooltipOptions={{ position: "top" }}
                    onClick={() => {
                        setEditUser(rowData);
                        onClick("editDialog");
                    }}
                    className="edit m-2 p-button-rounded"
                />
                <Button
                    tooltip="Rides"
                    icon="pi pi-car"
                    tooltipOptions={{ position: "top" }}
                    className="p-button-success m-2 p-button-rounded"
                    onClick={() => {
                        history.push(`/api/user/drivers/rides/list/${rowData?._id}/2`);
                    }}
                />
                <Avatar
                    icon="pi pi-user"
                    className="m-2 p-button-rounded"
                    size="large"
                    shape="circle"
                    tooltipOptions={{ position: "top" }}
                    tooltip="View Profile"
                    onClick={() => {
                        setEditUser(rowData);
                        onClick("showProfileDialog");
                    }}
                />
            </div>
        );
    };
    // const statusBody = (rowData) => {
    //     // return <div className={rowData.active.status ? "green" : "red"}>{rowData.active.status ? "Active" : "In Active"}</div>;
    //     return <div className={rowData.active.status ? "bg_green" : "bg_red"}>{rowData.active.status ? "Active" : "Inactive"}</div>;
    // };

    const rowClass = (data) => {
        if (data?.active?.status === true) {
            return {
                "bg-success": true, // Green background for active
            };
        } else if (data?.active?.status === false) {
            return {
                "bg-danger": true, // Red background for inactive
            };
        } else {
            return {}; // No class if no status
        }
    };

    const userTypeTemp = (rowData) => {
        return <div className="">{rowData.userType === "3" ? "Both" : rowData.userType === "2" ? "Passenger" : "Owner"}</div>;
    };

    const firstNameTemp = (rowData) => {
        const name = rowData.firstName;
        ////console.log(name)
        return <div>{name.charAt(0).toUpperCase() + name.slice(1)}</div>;
    };
    const lastNameTemp = (rowData) => {
        const name = rowData.lastName;
        ////console.log(name)
        return <div>{name.charAt(0).toUpperCase() + name.slice(1)}</div>;
    };
    const signupAsTemp = (rowData) => {
        if (rowData.signupAs == 1) {
            return "Owner";
        } else if (rowData.signupAs == 2) {
            return "Passenger";
        } else {
            return "";
        }
    };
    // const createdAtTemplate = (rowData) => {
    //     return <>{moment(rowData?.createdAt).format("MMMM Do YYYY, h:mm a")}</>;
    // };

    // const updatedAtTemplate = (rowData) => {
    //     return <>{moment(rowData?.updatedAt).format("MMMM Do YYYY, h:mm a")}</>;
    // };

    const createdAtTemplate = (rowData) => {
        const formattedDate = moment(rowData?.createdAt).format("DD/MM/YYYY"); // Only date format
        const fullDate = moment(rowData?.createdAt).format("MMMM Do YYYY, h:mm a"); // Full date with time

        return (
            <span className="date-tooltip-container">
                <span className="date-tooltip">{formattedDate}</span>
                <span className="date-full-tooltip">{fullDate}</span>
            </span>
        );
    };

    // Custom template for Updated At column (Similar to Created At)
    const updatedAtTemplate = (rowData) => {
        const formattedDate = moment(rowData?.updatedAt).format("DD/MM/YYYY"); // Only date format
        const fullDate = moment(rowData?.updatedAt).format("MMMM Do YYYY, h:mm a"); // Full date with time

        return (
            <span className="date-tooltip-container">
                <span className="date-tooltip">{formattedDate}</span>
                <span className="date-full-tooltip">{fullDate}</span>
            </span>
        );
    };

    // Custom header for Created At column
    const dateHeaderTemplate = () => (
        <>
            Created At
            <div style={{ fontSize: "0.8rem", color: "#6c757d" }}>DD/MM/YYYY</div>
        </>
    );

    // Custom header for Updated At column
    const updatedDateHeaderTemplate = () => (
        <>
            Updated At
            <div style={{ fontSize: "0.8rem", color: "#6c757d" }}>DD/MM/YYYY</div>
        </>
    );

    return (
        <>
            <Dialog header="Update Status" visible={editDialog} onHide={() => onHide("editDialog")} breakpoints={{ "960px": "75vw", "640px": "100vw" }} style={{ width: "70vw" }}>
                <EditUserDialog editUser={editUser} onHide={() => onHide("editDialog")} />
            </Dialog>

            <Dialog header="Profile" position="center" className="text-center" visible={showProfileDialog} style={{ width: "60vw", height: "" }} onHide={() => onHide("showProfileDialog")}>
                <div>{editUser != null && <UserProfileDialog userId={editUser._id} user={editUser} userType="1" />}</div>
            </Dialog>

            <div className="card">
                <div className="grid pb-3">
                    <div className="col-12 md:col-7">
                        <CustomPageHeader title={"Total Users"} />
                    </div>
                    <div className="mb-9 text-right col-12 md:col-5">
                        <span className="p-input-icon-right">
                            <InputText className="mb-3" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                        </span>
                    </div>
                </div>
                <DataTable loading={loading} rowClassName={rowClass} responsiveLayout="scroll" value={data} key="_id" filters={filters} globalFilterFields={["firstName", "lastName", "cnic", "mobile", "gender", "type"]} emptyMessage="No record found.">
                    {/* <Column body={(data, props) => <div>{props.rowIndex + 1}</div>} header="Sr" /> */}
                    <Column
                        header="Image"
                        body={(data) => {
                            return (
                                // <div>
                                //     <Image imageStyle={{ borderRadius: "50%", width: "50px", height: "50px" }} src={`${appUrl.fileBaseUrl}${data.profileImage}`?`${appUrl.fileBaseUrl}${data.profileImage}`:defaultImage} />
                                // </div>
                                <div>
                                    <Image imageStyle={{ borderRadius: "50%", width: "50px", height: "50px" }} src={data.profileImage ? `${appUrl.fileBaseUrl}${data.profileImage}` : defaultImage} onError={() => setDefaultImage("app/black-watch.jpg")} />
                                </div>
                            );
                        }}
                    />
                    <Column style={{ minWidth: "12rem" }} body={firstNameTemp} header="First Name" />
                    <Column style={{ minWidth: "12rem" }} body={lastNameTemp} header="Last Name" />
                    <Column style={{ minWidth: "12rem" }} field="cnic" header="CNIC" />
                    <Column style={{ minWidth: "12rem" }} field="mobile" header="Mobile" />
                    <Column style={{ minWidth: "12rem" }} body={userTypeTemp} header="Type" />
                    <Column style={{ minWidth: "12rem" }} field="gender" header="Gender" />
                    <Column style={{ minWidth: "12rem" }} body={signupAsTemp} header="Signup As" />
                    <Column
                        style={{ minWidth: "" }}
                        header="Log In Device"
                        body={(rowData) => {
                            if (rowData.loginDevice == "android") return <img height="25" width="25" src="images/android.png" />;
                            else if (rowData.loginDevice == "ios") return <img height="25" width="25" src="images/apple.png" />;
                            else return rowData.loginDevice;
                        }}
                    />
                    <Column
                        style={{ minWidth: "" }}
                        header="Signup Device"
                        body={(rowData) => {
                            if (rowData.signupDevice == "android") return <img height="25" width="25" src="images/android.png" />;
                            else if (rowData.signupDevice == "ios") return <img height="25" width="25" src="images/apple.png" />;
                            else return rowData.signupDevice;
                        }}
                    />
                    {/* <Column style={{ minWidth: "12rem" }} body={createdAtTemplate} header="Created At" />
                    <Column style={{ minWidth: "12rem" }} body={updatedAtTemplate} header="Updated At" /> */}
                    <Column style={{ minWidth: "8rem" }} body={createdAtTemplate} header={dateHeaderTemplate} />
                    <Column style={{ minWidth: "8rem" }} body={updatedAtTemplate} header={updatedDateHeaderTemplate} />
                    {/*<Column filter body={statusBodyDriver} header="Status" /> */}
                    {/* <Column body={statusBody} header="Status" /> */}
                    <Column header="Action" body={actionTemplate} />
                </DataTable>
                <Paginator first={first} rows={50} totalRecords={count} onPageChange={onPageChange} />
            </div>
        </>
    );
}
